/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import '../../VodafoneIdea.scss';
import VodafoneImg from '../../../../../../assets/images/vodafone.jpg';
import IdeaImg from '../../../../../../assets/images/idealogo.jpg';
import { JsonToTable } from 'react-json-to-table';
import {
  useExcelJsonList,
  useLoadRomsFormFieldListData,
  useLoadFieldValues,
  useLoadCompanySeal,
} from '../../../../../../hooks/form.hooks';
import { loadImageBlob } from '../../../../../../utils/aws.util';
import { useAuthContext } from '../../../../../../context/auth.context';
import { TERMS_VERSION, getVersionTerms, encode } from '../../cafCrf.util';
import { Spinner, Center, useTheme } from '@chakra-ui/react';

const CrfForm = ({ formData, queueData, romsActivity, submittedForms }) => {
  const [CrfData, setCrfData] = useState(false);
  const [crfDataupdated, setUpdatedCrfData] = useState(null);
  const [companySeal, setCompanySeal] = useState(null);
  const [photoGraph, setPhotograph] = useState(null);
  const [authorizedSignatoryName, setAuthSignName] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [acctMngSignImageData, setacctMngSignImageData] = useState(null);
  const [acctMngSignImageType, setacctMngSignImageType] = useState(null);
  const [photoGraphImageData, setphotoGraphImageData] = useState(null);
  const [photoGraphImageType, setphotoGraphImageType] = useState(null);
  const [excelJson, setexcelJson] = useState(null);

  const { mutate } = useLoadRomsFormFieldListData();
  const { mutate: loadOrderFormValues } = useLoadFieldValues();
  const { mutate: loadExcelJsonValues } = useExcelJsonList();
  const { mutate: loadCompanySeal } = useLoadCompanySeal();
  let componentRef = React.useRef();
  const chakraTheme = useTheme();

  const {
    state: { authData },
  } = useAuthContext();

  const fetchFieldListData = () => {
    mutate(
      {
        fieldList: {
          organization_id: authData.organization_id,
          account_id: authData.account_id,
          workforce_id: authData.workforce_id,
          form_id: formData.form_id,
        },
        fieldValues: {
          form_id: formData.form_id,
          form_transaction_id: formData.form_transaction_id
            ? formData.form_transaction_id
            : formData.data_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        },
      },
      {
        onSuccess: async data => {
          let finalFields = data;
          setCrfData(false);
          setUpdatedCrfData(finalFields);
        },
        onError: async err => {
          console.log('error occurred while loading fields', err);
        },
      }
    );
  };

  const fetchOrderFormValues = () => {
    let orderForm = submittedForms.find(
      item => item.form_id === romsActivity.order_form_id
    );
    loadOrderFormValues(
      {
        form_id: romsActivity?.order_form_id,
        form_transaction_id: !!orderForm
          ? orderForm.data_form_transaction_id
          : 0,
      },
      {
        onSuccess: async res => {
          if (res.length > 0) {
            let ExcelIndex = res[0].activity_inline_data.findIndex(
              res1 => res1.field_id === 11746
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl = res[0].activity_inline_data[ExcelIndex].field_value;
            }
            if (!!excelUrl) {
              loadExcelJsonValues(
                { bucket_url: excelUrl },
                {
                  onSuccess: async result => {
                    setexcelJson(JSON.parse(result));
                  },
                }
              );
            }
          }
        },
      }
    );
  };

  const fetchCompanySeal = () => {
    loadCompanySeal(
      {
        form_id: 1112,
        field_id: 0,
        activity_id: queueData.activity_id,
      },
      {
        onSuccess: async res => {
          let companySeal = '';
          let data = [];
          if (res.length > 0) {
            data = JSON.parse(res[0].data_entity_inline).form_submitted;
          } else {
            data = [];
          }
          data.forEach(function (fieldObj, index) {
            if (fieldObj.field_id === 8584) {
              companySeal = fieldObj.field_value;
            }
          });
          setCompanySeal(companySeal);
        },
      }
    );
  };

  useEffect(() => {
    setCrfData(true);
    fetchCompanySeal();
    fetchFieldListData();
    fetchOrderFormValues();
  }, []);

  useEffect(() => {
    let data = crfDataupdated;
    if (!!data) {
      if (!!companySeal) {
        // customer company seal
        let custCompanySeal = companySeal;
        loadImageBlob(custCompanySeal, (data, type) => {
          setImageData(data);
          setImageType(type);
        });
      }
      if (!!data[8561].value) {
        // manager sign url
        let acctManagerSignUrl = data[8561].value;
        loadImageBlob(acctManagerSignUrl, (data, type) => {
          setacctMngSignImageData(data);
          setacctMngSignImageType(type);
        });
      }
    }
  }, [crfDataupdated, companySeal]);

  let crfContentView = '';
  if (!!crfDataupdated) {
    let customerSignSplit = crfDataupdated[8558].value.split(' ');
    let firstCaps;
    let secondBold;
    let Signcss;
    let Overlapcss = 'signate_mplscrf_in float_img';
    if (customerSignSplit.length > 1) {
      firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase();
      secondBold = customerSignSplit['1'];
      Signcss = 'signate_img input_sign';
    } else {
      firstCaps = customerSignSplit['0'];
      secondBold = '';
      Signcss = 'signate_img input_sign under_line';
    }

    crfContentView = (
      <div>
        <div
          style={{ textAlign: 'left' }}
          className="tab_content"
          ref={el => (componentRef = el)}
        >
          <page size="A4">
            <div className="section1">
              <div className="font8">
                <p className="over_hid">
                  <img src={VodafoneImg} />
                  <img src={IdeaImg} className="pullr" />
                </p>
                <div className="pad_20t">
                  <div className="over_hid">
                    <div className="pd_t5">
                      {' '}
                      <span className="textcenter width70 fleft font15 mplhed">
                        MPLS Change Request Form
                      </span>
                      <div className="caf">
                        CAF ID
                        <input
                          className="form_col"
                          type="text"
                          name=""
                          value={crfDataupdated[8375].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="over_hid mar_t-5">
                    <div className="pd_t5">
                      <strong>Company(Customer) Name</strong>{' '}
                      <span className="pad_l10">
                        <input
                          className="form_col_input"
                          type="text"
                          name=""
                          value={crfDataupdated[8367].value}
                          readOnly
                        />
                      </span>
                      <div className="caf_inner">
                        <strong>Account Code</strong>
                        <input
                          className="form_col no_top"
                          type="text"
                          name=""
                          value={crfDataupdated[8368].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="sign font12">
                    <strong>Authorised Signatory Details</strong>
                  </p>
                </div>
                <div className="over_hid auth">
                  <div className="fleft width45">
                    Name
                    <input
                      className="form_col width85"
                      type="text"
                      name=""
                      value={crfDataupdated[8376].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Contact No:
                    <input
                      className="form_col width59"
                      type="text"
                      name=""
                      value={crfDataupdated[8377].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width30">
                    Email:
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={crfDataupdated[8378].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_t10">
                  <div className="fleft width45 textcenter">
                    {' '}
                    Feasibility ID (FR ID){' '}
                  </div>
                  <div className="fleft width45 textcenter">
                    <input
                      className="form_col width50"
                      type="text"
                      name=""
                      value={crfDataupdated[8370].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_5t">
                  <div className="fleft width45 textcenter">
                    {' '}
                    Please mention circuit id.
                  </div>
                  <div className="fleft width45 textcenter">
                    <input
                      className="form_col width50"
                      type="text"
                      name=""
                      value={crfDataupdated[8371].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_5t">
                  <div className="fleft width45 textcenter">
                    Please mention VOX ID
                  </div>
                  <div className="fleft width45 textcenter">
                    <input
                      className="form_col width50"
                      type="text"
                      name=""
                      value={crfDataupdated[8372].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="margin0 font15">TYPE OF CHANGE REQUEST ORDER</p>
                <div className="over_hid pad_t15">
                  <div className="fleft width45 pad_l5per">
                    <div className="over_hid">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        1 Company Name Change{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Company Name Change') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        2 Customer contact Change{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Customer Contact Change') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>

                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        3 Billing Adress change{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Billing Address Change') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        4 CIR Bandwidth Upgrade - same interface{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('CIR Bandwidth Upgrade - same interface') >=
                        0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        5 CIR Bandwidth Upgrade with interface change{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf(
                            'CIR Bandwidth Upgrade with interface change'
                          ) >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        6 CIR Bandwidth Downgrade- Same Interface{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('CIR Bandwidth Downgrade- Same Interface') >=
                        0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        7 CIR Bandwidth Downgrade- Interface change{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf(
                            'CIR Bandwidth Downgrade- Interface change'
                          ) >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        8 Interface Change{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Interface Change') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        9 Interface Change- Media Change{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Interface Change- Media Change') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        10 Interface Change- LM provider change{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Interface Change- LM provider change') >=
                        0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        11 Change in className of Service{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Change in Class of Service') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        12 Change in Topology{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Change in Topology') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        13 Change in Protocol{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Change in Protocol') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        14 Change in CPE- Add/ Remove{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Change in CPE- Add/ Remove') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fleft width45 pad_l5per">
                    <div className="over_hid">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        15 Feature Change- Multicast{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Feature Change- Multicast') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        16 Site Shifting{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Site Shifting') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        17 Billing Periodicity Change{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Billing Periodicity Change') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        18 Subscribe Managed Services{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Subscribe Managed Services') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        19 Un Subscribe Managed Services{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Un Subscribe Managed Services') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        20 Change of Managed Services Variant{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Change of Managed Services Variant') >=
                        0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        21 Change in SLA Type{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Change in SLA Type') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        22 SSL VPN - Change in Domain Name{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('SSL VPN - Change in Domain Name') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        23 SSL VPN - Change in number of concurrent users{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf(
                            'SSL VPN - Change in number of concurrent users'
                          ) >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        24 IP Sec VPN - Change in port bandwidth{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('IP Sec VPN - Change in port bandwidth') >=
                        0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        25 Dial Up VPN - Change in port bandwidth{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Dial Up VPN - Change in port bandwidth') >=
                        0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        26 2G 3G API Solution - Change in port bandwidth{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf(
                            '2G 3G API Solution - Change in port bandwidth'
                          ) >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        27 Change in Self care service variant{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Change in CPE- Add/ Remove') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        28 Revision in charges{' '}
                      </div>
                      <div className="fleft">
                        {crfDataupdated[8373].value
                          .split('|')
                          .indexOf('Revision in charges') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Company Name Change
                </p>
                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData != null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData != null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    Existing Name of company
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8380)
                          ? crfDataupdated[8380].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">&nbsp;</div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    New Name of company
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8381)
                          ? crfDataupdated[8381].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">&nbsp;</div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Customer Contact Details Change
                </p>

                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    Existing telephone Number
                  </div>
                  <div className="fleft width49">
                    {' '}
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8383)
                          ? crfDataupdated[8383].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    New telephone Number
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8384)
                          ? crfDataupdated[8384].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    Existing email address
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8385)
                          ? crfDataupdated[8385].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    New email address
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8386)
                          ? crfDataupdated[8386].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Billing Address Change
                </p>
                <p className="font12 margin0"> New Billing Address</p>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3">
                    Contact Person name
                  </div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8389)
                          ? crfDataupdated[8389].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">Designation</div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            crfDataupdated.hasOwnProperty(8390)
                              ? crfDataupdated[8390].value
                              : ''
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3 textcenter">
                        <span className="">Dept. Name</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            crfDataupdated.hasOwnProperty(8391)
                              ? crfDataupdated[8391].value
                              : ''
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3">Address</div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8392)
                          ? crfDataupdated[8392].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3"> &nbsp; </div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        City / Village / Post Office
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            crfDataupdated.hasOwnProperty(8393)
                              ? crfDataupdated[8393].value
                              : ''
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid25">
                    <div className="check over_hid ">
                      <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                        <span className="">Pin</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            crfDataupdated.hasOwnProperty(8394)
                              ? crfDataupdated[8394].value
                              : ''
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid25">
                    <div className="check over_hid ">
                      <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                        <span className="">Telephone</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            crfDataupdated.hasOwnProperty(8395)
                              ? crfDataupdated[8395].value
                              : ''
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="auth margin0 over_hid ">
                  <div className="fleft wid120p pad_t3 text_r">
                    Landmark &nbsp; &nbsp;{' '}
                  </div>
                  <div className="fleft width63">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8396)
                          ? crfDataupdated[8396].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </div>

                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Site Shifting Installation Address Change
                </p>
                <p className="font12 margin0"> New Installation Address</p>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3">
                    Contact Person name
                  </div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8399)
                          ? crfDataupdated[8399].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">Designation</div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            crfDataupdated.hasOwnProperty(8400)
                              ? crfDataupdated[8400].value
                              : ''
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3 textcenter">
                        <span className="">Dept. Name</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            crfDataupdated.hasOwnProperty(8401)
                              ? crfDataupdated[8401].value
                              : ''
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3">Address</div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8402)
                          ? crfDataupdated[8402].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3"> &nbsp; </div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        City / Village / Post Office
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            crfDataupdated.hasOwnProperty(8403)
                              ? crfDataupdated[8403].value
                              : ''
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid25">
                    <div className="check over_hid ">
                      <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                        <span className="">Pin</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={
                            crfDataupdated.hasOwnProperty(8404)
                              ? crfDataupdated[8404].value
                              : ''
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid25">
                    <div className="check over_hid ">
                      <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                        <span className="">Telephone</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={
                            crfDataupdated.hasOwnProperty(8405)
                              ? crfDataupdated[8405].value
                              : ''
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="auth margin0 over_hid ">
                  <div className="fleft wid120p pad_t3 text_r">
                    Landmark &nbsp; &nbsp;{' '}
                  </div>
                  <div className="fleft width63">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8406)
                          ? crfDataupdated[8406].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  CIR Bandwidth Upgrade - Same Interface
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width20 pad_t3">
                      Existing Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8408)
                            ? crfDataupdated[8408].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                    <div className="fleft width25 pad_t3 pad_l50">
                      New Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8409)
                            ? crfDataupdated[8409].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  CIR Bandwidth Downgrade - Same Interface
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width20 pad_t3">
                      Existing Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8411)
                            ? crfDataupdated[8411].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                    <div className="fleft width25 pad_t3 pad_l50">
                      New Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8412)
                            ? crfDataupdated[8412].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  CIR Bandwidth Upgrade with Interface / LM / Media Change
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width20 pad_t3">
                      Existing Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8414)
                            ? crfDataupdated[8414].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                    <div className="fleft width25 pad_t3 pad_l50">
                      New Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8415)
                            ? crfDataupdated[8415].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      Existing Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8416].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8416].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8416].value === 'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8416].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8416].value === 'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8416].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      New Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8417].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8417].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8417].value === 'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8417].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8417].value === 'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8417].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Media{' '}
                    </div>
                    <div className="fleft pad_r25">
                      <span>Existing Media</span>
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8418].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8418].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8418].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8418].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                    <div className="fleft pad_r25">
                      <span>New Media</span>
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8419].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8419].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8419].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8419].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Last Mile provider
                    </div>
                    <div className="fleft wid36">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        Existing LM Provider{' '}
                      </span>
                      <input
                        className="form_col width56im"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8421)
                            ? crfDataupdated[8421].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft wid25">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        New LM Provider
                      </span>
                      <input
                        className="form_col width47im"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8422)
                            ? crfDataupdated[8422].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  CIR Bandwidth Downgrade with Interface / LM / Media Change
                </p>
                <div className={Overlapcss}>
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData != null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData != null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width20 pad_t3">
                      Existing Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8426)
                            ? crfDataupdated[8426].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                    <div className="fleft width25 pad_t3 pad_l50">
                      New Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8427)
                            ? crfDataupdated[8427].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      Existing Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8428].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8428].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8428].value === 'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8428].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8428].value === 'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8428].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      New Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8429].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8429].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8429].value === 'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8429].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8429].value === 'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8429].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Media{' '}
                    </div>
                    <div className="fleft pad_r25">Existing Media</div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8430].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8430].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8430].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8430].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                    <div className="fleft pad_r25">New Media</div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8431].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8431].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8431].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8431].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Last Mile provider
                    </div>
                    <div className="fleft wid36">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        Existing LM Provider{' '}
                      </span>
                      <input
                        className="form_col width56im"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8433)
                            ? crfDataupdated[8433].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft wid25">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        New LM Provider
                      </span>
                      <input
                        className="form_col width47im"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8434)
                            ? crfDataupdated[8434].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <br />
                <br />
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Interface Change{' '}
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      Existing Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8435].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8435].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8435].value === 'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8435].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8435].value === 'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8435].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      New Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8436].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8436].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8436].value === 'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8436].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8436].value === 'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8436].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Interface Change with Media / LM Change
                </p>
                <div className="pad_t10">
                  <div className="check gstcheck over_hid">
                    <div className="fleft width130px pad_t3">
                      Existing Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8438].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8438].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8438].value === 'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8438].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8438].value === 'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8438].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8438].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft width130px pad_t3">
                      New Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8439].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8439].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8439].value === 'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8439].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8439].value === 'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8439].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8439].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Media{' '}
                    </div>
                    <div className="fleft pad_r25">Existing Media</div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8441].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8441].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8441].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8441].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                    <div className="fleft pad_r25">New Media</div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8442].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8442].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8442].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8442].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Last Mile provider
                    </div>
                    <div className="fleft wid36">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        Existing LM Provider{' '}
                      </span>
                      <input
                        className="form_col width56im"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8444)
                            ? crfDataupdated[8444].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft wid25">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        New LM Provider
                      </span>
                      <input
                        className="form_col width47im"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8445)
                            ? crfDataupdated[8445].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  CoS Change
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft width15 pad_t3">
                      {' '}
                      Existing CoS Type{' '}
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8447].value === 'Standard' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Standard
                    </div>
                    <div className="fleft">
                      {crfDataupdated[8447].value === 'Customized' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customized
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft width15 pad_t3"> New CoS Type </div>
                    <div className="fleft width16">
                      {crfDataupdated[8448].value === 'Standard' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Standard
                    </div>
                    <div className="fleft">
                      {crfDataupdated[8448].value === 'Customized' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customized
                    </div>
                  </div>
                </div>
                <p className="margin0 pad_l10">
                  If COS Type is customized, enter COS split in percentage
                </p>
                <div className="check over_hid pad_t10">
                  <div className="fleft width6 pad_t3"> </div>
                  <div className="fleft width10 pad_r15">
                    {' '}
                    CoS 1<br></br>
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8450)
                          ? crfDataupdated[8450].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                  <div className="fleft width10 pad_r15">
                    CoS 2<br></br>
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8451)
                          ? crfDataupdated[8451].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                  <div className="fleft width10 pad_r15">
                    CoS 3 <br></br>
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8452)
                          ? crfDataupdated[8452].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                  <div className="fleft width10 pad_r15">
                    {' '}
                    CoS 4 <br></br>
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8453)
                          ? crfDataupdated[8453].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                  <div className="fleft width10 pad_r15">
                    {' '}
                    Total<br></br>
                    <input
                      className="form_col width75"
                      type="text"
                      name=""
                      value={
                        crfDataupdated.hasOwnProperty(8454)
                          ? crfDataupdated[8454].value
                          : ''
                      }
                      readOnly
                    />
                  </div>
                  <div className="fleft pad_t15"> (Total should be 100%) </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Topology Change
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      Existing Topology{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8456].value === 'Hub' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Hub
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8456].value === 'Spoke' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Spoke
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8456].value === 'any-to-any' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      any-to-any
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">New Topology </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8457].value === 'Hub' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Hub
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8457].value === 'Spoke' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Spoke
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8457].value === 'any-to-any' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      any-to-any
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Protocol change
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft wid30per pad_t3">
                      Existing Protocol / Encapsulation{' '}
                    </div>
                    <div className="fleft wid20per">
                      {crfDataupdated[8459].value === 'IP' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      IP
                    </div>
                    <div className="fleft wid20per">
                      {crfDataupdated[8459].value === 'Ethernet' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet
                    </div>
                    <div className="fleft wid20per">
                      {crfDataupdated[8459].value === 'HDLC/ PPP' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      HDLC/ PPP
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid">
                    <div className="fleft wid30per pad_t3">
                      New Protocol / Encapsulation{' '}
                    </div>
                    <div className="fleft wid20per">
                      {crfDataupdated[8460].value === 'IP' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      IP
                    </div>
                    <div className="fleft wid20per">
                      {crfDataupdated[8460].value === 'Ethernet' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet
                    </div>
                    <div className="fleft wid20per">
                      {crfDataupdated[8460].value === 'HDLC/ PPP' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      HDLC/ PPP
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Addition of CPE
                </p>
                <div
                  className="domestictable"
                  align="left"
                  style={{ width: '75%' }}
                >
                  <table width="70%" border="0" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr>
                        <td>Sr. No.</td>
                        <td>Type (Standard/Other)</td>
                        <td>Make</td>
                        <td>Model</td>
                        <td>Version</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>
                          {crfDataupdated.hasOwnProperty(8463)
                            ? crfDataupdated[8463].value
                            : ''}
                        </td>
                        <td>
                          {crfDataupdated.hasOwnProperty(8464)
                            ? crfDataupdated[8464].value
                            : ''}
                        </td>
                        <td>
                          {crfDataupdated.hasOwnProperty(8465)
                            ? crfDataupdated[8465].value
                            : ''}
                        </td>
                        <td>
                          {crfDataupdated.hasOwnProperty(8466)
                            ? crfDataupdated[8466].value
                            : ''}
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Deletion of CPE
                </p>

                <div
                  className="domestictable"
                  align="left"
                  style={{ width: '75%' }}
                >
                  <table width="70%" border="0" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr>
                        <td>Sr. No.</td>
                        <td>Type (Standard/Other)</td>
                        <td>Make</td>
                        <td>Model</td>
                        <td>Version</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>
                          {crfDataupdated.hasOwnProperty(8468)
                            ? crfDataupdated[8468].value
                            : ''}
                        </td>
                        <td>
                          {crfDataupdated.hasOwnProperty(8469)
                            ? crfDataupdated[8469].value
                            : ''}
                        </td>
                        <td>
                          {crfDataupdated.hasOwnProperty(8470)
                            ? crfDataupdated[8470].value
                            : ''}
                        </td>
                        <td>
                          {crfDataupdated.hasOwnProperty(8471)
                            ? crfDataupdated[8471].value
                            : ''}
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="signate float_img">
                    {firstCaps !== '' ? (
                      <div style={{ padding: '10px' }} className={Signcss}>
                        {firstCaps + '  .  '}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    {imageData != null && imageData !== '' ? (
                      <div style={{ padding: '10px' }} className="signate_img">
                        <img
                          src={
                            imageData != null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Change in Service Variant
                </p>
                <div className="pad_t10">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 pad_r25 width16">
                      {' '}
                      Existing Service Variant{' '}
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8473].value === 'Layer 3 MPLS' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Layer 3 MPLS"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Layer 3 MPLS
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8473].value === 'Layer 2 MPLS' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Layer 2 MPLS"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Layer 2 MPLS
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8473].value === 'Multicast VPN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Multicast VPN"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Multicast VPN
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 pad_r25 width16">
                      {' '}
                      New Service Variant{' '}
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8474].value === 'Layer 3 MPLS' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Layer 3 MPLS"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Layer 3 MPLS
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8474].value === 'Layer 2 MPLS' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Layer 2 MPLS"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Layer 2 MPLS
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8474].value === 'Multicast VPN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Multicast VPN"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Multicast VPN
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Billing Periodicity Change
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width20">
                      {' '}
                      Existing Billing Periodicity{' '}
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8476].value === 'Monthly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Monthly"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Monthly
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8476].value === 'Bi-Monthly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Bi-Monthly"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Bi-Monthly
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8476].value === 'Quarterly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Quarterly"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Quarterly
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8476].value === 'Half Yearly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Half Yearly"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Half Yearly
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8476].value === 'Annually' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Annually"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Annually
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width20">
                      Revised Billing Periodicity{' '}
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8477].value === 'Monthly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Monthly"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Monthly
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8477].value === 'Bi-Monthly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Bi-Monthly"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Bi-Monthly
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8477].value === 'Quarterly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Quarterly"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Quarterly
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8477].value === 'Half Yearly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Half Yearly"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Half Yearly
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8477].value === 'Annualy' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Annualy"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Annualy
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Subscribe Managed Services
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width20">
                      {' '}
                      Managed services required{' '}
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8479].value === 'Yes' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="managed services required"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width20 text_r pad_r5">
                      {' '}
                      Managed Service Variants{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8480].value === 'Standard' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Standard
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8480].value === 'Silver' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8480].value === 'Silver Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver Plus
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8480].value === 'Gold' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8480].value === 'Gold Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold Plus
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8480].value === 'Platinum' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Platinum
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8480].value === 'Platinum Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Platinum Plus
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Un Subscribe Managed Services
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width20">
                      {' '}
                      Managed services required{' '}
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8482].value === 'No' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Change of Managed Services Variant
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width22">
                      {' '}
                      Existing Managed Service Variants{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8484].value === 'Standard' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Change of Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Standard
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8484].value === 'Silver' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Change of Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8484].value === 'Silver Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Change of Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver Plus
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8484].value === 'Gold' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Change of Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8484].value === 'Gold Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Change of Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold Plus
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8484].value === 'Platinum' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Change of Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Platinum
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8484].value === 'Platinum Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Change of Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Platinum Plus
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width22">
                      {' '}
                      New Managed Service Variants{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8485].value === 'Standard' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="New Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Standard
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8485].value === 'Silver' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="New Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8485].value === 'Silver Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="New Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver Plus
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8485].value === 'Gold' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="New Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8485].value === 'Gold Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="New Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold Plus
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8485].value === 'Platinum' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="New Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Platinum
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8485].value === 'Platinum Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="New Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Platinum Plus
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Change in SLA Type
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft width15 pad_t3">
                      {' '}
                      Existing SLA Type{' '}
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8487].value === 'Standard' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="New Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Standard
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8487].value === 'Customized' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="New Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customized
                    </div>
                    <div className="fleft width16 pad_5t"> New SLA Type</div>
                    <div className="fleft width16">
                      {crfDataupdated[8488].value === 'Standard' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="New Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Standard
                    </div>
                    <div className="fleft width16">
                      {crfDataupdated[8488].value === 'Customized' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="New Managed Service Variants"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customized
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft width20 pad_7t textcenter  pad_r5">
                      If SLA Type Customized{' '}
                    </div>
                    <div className="fleft width16 pad_r20">
                      Service Availability (%)
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8490)
                            ? crfDataupdated[8490].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width12 pad_r20">
                      MTTR (Hours)
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8491)
                            ? crfDataupdated[8491].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width12 pad_r20">
                      Latency (ms)
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8492)
                            ? crfDataupdated[8492].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width12 pad_r20">
                      Packet Loss (%)
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8493)
                            ? crfDataupdated[8493].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width12">
                      Jitter (ms)
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8494)
                            ? crfDataupdated[8494].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Change in Self care service variant
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width22">
                      {' '}
                      Existing Self Care Service Variant{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8496].value === 'None' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      None
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8496].value === 'Silver' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8496].value === 'Silver Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver Plus
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8496].value === 'Gold' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8496].value === 'Gold Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold Plus
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8496].value === 'Platinum' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Platinum
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8496].value === 'Platinum Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Platinum Plus
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width22">
                      {' '}
                      New Self Care Service Variant{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8497].value === 'None' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      None
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8497].value === 'Silver' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8497].value === 'Silver Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver Plus
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8497].value === 'Gold' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8497].value === 'Gold Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold Plus
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8497].value === 'Platinum' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Platinum
                    </div>
                    <div className="fleft pad_r25">
                      {crfDataupdated[8497].value === 'Platinum Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Existing Self Care Service Variant"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Platinum Plus
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  SSL VPN - Change in Domain Name
                </p>
                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData != null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width25 pad_t3">
                      Existing Customer Domain name{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8499)
                            ? crfDataupdated[8499].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width20 pad_t3 pad_l50">
                      {' '}
                      New Customer Domain name{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8500)
                            ? crfDataupdated[8500].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  IPSEC VPN - Change in Port Bandwidth
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width25 pad_t3">
                      Existing Port Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8502)
                            ? crfDataupdated[8502].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                    <div className="fleft width20 pad_t3 pad_l50">
                      New port bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8503)
                            ? crfDataupdated[8503].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Dial Up VPN - Change in Port Bandwidth
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width25 pad_t3">
                      Existing Port Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8505)
                            ? crfDataupdated[8505].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                    <div className="fleft width20 pad_t3 pad_l50">
                      New port bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8506)
                            ? crfDataupdated[8506].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  2G 3G API solution - Change in Port Bandwidth
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width25 pad_t3">
                      Existing Port Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8508)
                            ? crfDataupdated[8508].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                    <div className="fleft width20 pad_t3 pad_l50">
                      New port bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col wid60px"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8509)
                            ? crfDataupdated[8509].value
                            : ''
                        }
                        readOnly
                      />
                      <span className="pad_t5 flright">(Kbps / Mbps)</span>{' '}
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Revision in Charges
                </p>
                <p>
                  (Only do required changes. Leave others blank if no change)
                </p>
                <div className="char">
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Specify Currency{' '}
                    </div>
                    <div className="fleft width7">
                      {crfDataupdated[8512].value === 'INR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Specify Currency"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Specify Currency"
                          value=""
                          disabled
                        />
                      )}
                      INR{' '}
                    </div>
                    <div className="fleft width7">
                      {crfDataupdated[8512].value === 'US$' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Specify Currency"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Specify Currency"
                          value=""
                          disabled
                        />
                      )}
                      US${' '}
                    </div>
                    <div className="fleft width15 textcenter">
                      {' '}
                      One Time(A){' '}
                    </div>
                    <div className="fleft width15 textcenter">
                      {' '}
                      Recurring (B){' '}
                    </div>
                    <div className="fleft width15 textcenter">
                      {' '}
                      Security Deposit ©{' '}
                    </div>
                    <div className="fleft width15 textcenter">
                      {' '}
                      Grand Total (A+B+C){' '}
                    </div>
                  </div>
                  <div className="check over_hid">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Service Rental
                    </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8513)
                            ? crfDataupdated[8513].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8514)
                            ? crfDataupdated[8514].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8515)
                            ? crfDataupdated[8515].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8565)
                            ? crfDataupdated[8565].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      IP Address Charges
                    </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7">&nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8516)
                            ? crfDataupdated[8516].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8517)
                            ? crfDataupdated[8517].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8566)
                            ? crfDataupdated[8566].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> SLA Charges </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8518)
                            ? crfDataupdated[8518].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8519)
                            ? crfDataupdated[8519].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8567)
                            ? crfDataupdated[8567].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      Self Care Portal Service Charges
                    </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8520)
                            ? crfDataupdated[8520].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8521)
                            ? crfDataupdated[8521].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8568)
                            ? crfDataupdated[8568].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Managed Services Charges
                    </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8522)
                            ? crfDataupdated[8522].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8523)
                            ? crfDataupdated[8523].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8569)
                            ? crfDataupdated[8569].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Managed CPE Charges{' '}
                    </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8524)
                            ? crfDataupdated[8524].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8525)
                            ? crfDataupdated[8525].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8570)
                            ? crfDataupdated[8570].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> CPE Rentals </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8526)
                            ? crfDataupdated[8526].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8527)
                            ? crfDataupdated[8527].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8528)
                            ? crfDataupdated[8528].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8571)
                            ? crfDataupdated[8571].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft pad_t5 pad_r5">CPE 1 </div>
                    <div className="fleft cpelist">
                      <input
                        className="form_col wid90per"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8529)
                            ? crfDataupdated[8529].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8530)
                            ? crfDataupdated[8530].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8531)
                            ? crfDataupdated[8531].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8572)
                            ? crfDataupdated[8572].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft pad_t5 pad_r5">CPE 2 </div>
                    <div className="fleft cpelist">
                      <input
                        className="form_col wid90per"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8532)
                            ? crfDataupdated[8532].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8533)
                            ? crfDataupdated[8533].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8534)
                            ? crfDataupdated[8534].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8573)
                            ? crfDataupdated[8573].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft pad_t5 pad_r5">CPE 3</div>
                    <div className="fleft cpelist">
                      <input
                        className="form_col wid90per"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8535)
                            ? crfDataupdated[8535].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8536)
                            ? crfDataupdated[8536].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8537)
                            ? crfDataupdated[8537].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8574)
                            ? crfDataupdated[8574].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft pad_t5 pad_r5">CPE 4 </div>
                    <div className="fleft cpelist">
                      <input
                        className="form_col wid90per"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8538)
                            ? crfDataupdated[8538].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8539)
                            ? crfDataupdated[8539].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8540)
                            ? crfDataupdated[8540].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8575)
                            ? crfDataupdated[8575].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft pad_t5 pad_r5">CPE 5 </div>
                    <div className="fleft cpelist">
                      <input
                        className="form_col wid90per"
                        type="text"
                        name=""
                        value=""
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8541)
                            ? crfDataupdated[8541].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8542)
                            ? crfDataupdated[8542].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8543)
                            ? crfDataupdated[8543].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8576)
                            ? crfDataupdated[8576].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      Miscellaneous Charges - 1{' '}
                    </div>
                    <div className="fleft width7 pad_t5"> &nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8544)
                            ? crfDataupdated[8544].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8545)
                            ? crfDataupdated[8545].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value=""
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8577)
                            ? crfDataupdated[8577].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      Miscellaneous Charges - 2{' '}
                    </div>
                    <div className="fleft width7 pad_t5"> &nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8546)
                            ? crfDataupdated[8546].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8547)
                            ? crfDataupdated[8547].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8578)
                            ? crfDataupdated[8578].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Shifting Charges{' '}
                    </div>
                    <div className="fleft width7 pad_t5"> &nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8548)
                            ? crfDataupdated[8548].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8579)
                            ? crfDataupdated[8579].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      CPE Replacement Charges
                    </div>
                    <div className="fleft width7 pad_t5"> &nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8549)
                            ? crfDataupdated[8549].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8580)
                            ? crfDataupdated[8580].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <p>
                  <strong>** Taxes as applicable.</strong>
                </p>
              </div>
            </div>
            <div className="signate float_img">
              {firstCaps !== '' ? (
                <div style={{ padding: '10px' }} className={Signcss}>
                  {firstCaps + '  .  '}
                  <span className="under_line">{secondBold}</span>
                </div>
              ) : (
                ''
              )}
              {imageData !== null && imageData !== '' ? (
                <div style={{ padding: '10px' }} className="signate_img">
                  <img
                    src={
                      imageData != null
                        ? 'data:' + imageType + ';base64,' + encode(imageData)
                        : ''
                    }
                    alt={'Company Seal'}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  COMMENTS
                </p>
                <div className="cmt_spl">
                  {' '}
                  Comments/ Special Considerations:{' '}
                  {crfDataupdated.hasOwnProperty(8551)
                    ? crfDataupdated[8551].value
                    : ''}{' '}
                </div>
                <div className="over_hid">
                  <div className="fleft width65 pad_t10">
                    <p className="margin0">
                      <strong>Declaration</strong>
                    </p>
                    <p className="margin0">
                      I / We confirm having received, read and understood the
                      Product Terms & Conditions (provided overleaf) and the
                      General Terms & Conditions. I / We further confirm that
                      the tariff plan selected and applicable rates form part of
                      this Agreement (as defined herein) and I / We agree to
                      abide by the Applicable Law in force and also any
                      statutory amendments, or new legistlations as may be
                      enacted from time to time, in so far as they realte to the
                      services. I / We hereby declare and confirm that the above
                      information provided by us is true and correct in all
                      respects and I / We hereby undertake to be bound by the
                      same.{' '}
                    </p>
                    <p className="margin0 pad_5t">
                      Authorised Signatory's Name
                    </p>
                    <p className="margin0">
                      <input
                        className="form_col width98per cmnt_input"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8376)
                            ? crfDataupdated[8376].value
                            : ''
                        }
                        readOnly
                      />
                    </p>
                    <div className="over_hid pad_t15">
                      <div className="fleft width30">
                        <p className="margin0">Designation</p>
                        <p className="margin0">
                          <input
                            className="form_col widthfull cmnt_input"
                            type="text"
                            name=""
                            value={
                              crfDataupdated.hasOwnProperty(8555)
                                ? crfDataupdated[8555].value
                                : ''
                            }
                            readOnly
                          />
                        </p>
                      </div>
                      <div className="fleft width30 mar_l20">
                        <p className="margin0">Place</p>
                        <p className="margin0">
                          <input
                            className="form_col widthfull cmnt_input"
                            type="text"
                            name=""
                            value={
                              crfDataupdated.hasOwnProperty(8556)
                                ? crfDataupdated[8556].value
                                : ''
                            }
                            readOnly
                          />
                        </p>
                      </div>
                      <div className="fleft width30 mar_l20">
                        <p className="margin0">Date</p>
                        <p className="margin0">
                          <input
                            className="form_col width96per cmnt_input"
                            type="text"
                            name=""
                            value={
                              crfDataupdated.hasOwnProperty(8557)
                                ? crfDataupdated[8557].value
                                : ''
                            }
                            readOnly
                          />
                        </p>
                      </div>
                    </div>
                    <p className="margin0 pad_5t">Account Manager Name</p>
                    <p className="margin0">
                      <input
                        className="form_col width98per cmnt_input"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8559)
                            ? crfDataupdated[8559].value
                            : ''
                        }
                        readOnly
                      />
                    </p>
                    <p className="margin0 pad_20t">
                      Account Manager Circle Office
                    </p>
                    <p className="margin0">
                      <input
                        className="form_col  wid120p cmnt_input"
                        type="text"
                        name=""
                        value={
                          crfDataupdated.hasOwnProperty(8560)
                            ? crfDataupdated[8560].value
                            : ''
                        }
                        readOnly
                      />
                    </p>
                    <div className="over_hid pad_20t">
                      <div className="fleft width50">
                        <p className="margin0">Channel Partner Name</p>
                        <p className="margin0">
                          <input
                            className="form_col widthfull cmnt_input"
                            type="text"
                            name=""
                            value={
                              crfDataupdated.hasOwnProperty(8562)
                                ? crfDataupdated[8562].value
                                : ''
                            }
                            readOnly
                          />
                        </p>
                      </div>
                      <div className="flright width30">
                        <p className="margin0">Channel Partner Code</p>
                        <p className="margin0">
                          <input
                            className="form_col width92per cmnt_input"
                            type="text"
                            name=""
                            value={
                              crfDataupdated.hasOwnProperty(8563)
                                ? crfDataupdated[8563].value
                                : ''
                            }
                            readOnly
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flright width30">
                    <div
                      style={{
                        padding: '5px',
                        lineHeight: 'normal',
                        width: '214px',
                      }}
                      className="signate"
                    >
                      {firstCaps !== '' ? (
                        <div className={Signcss}>
                          {firstCaps + '  .  '}
                          <span className="under_line">{secondBold}</span>
                        </div>
                      ) : (
                        ''
                      )}
                      <div>
                        Signature of the Customer / Authorised signatory with in
                        this box only (In case of institutinal, please affix
                        office/company seal){' '}
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '5px',
                        height: '120px',
                        marginTop: '140px',
                        width: '216px',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      className="signate"
                    >
                      {acctMngSignImageData != null &&
                      acctMngSignImageData !== '' ? (
                        <img
                          height="90px"
                          width="100px"
                          src={
                            acctMngSignImageData != null
                              ? 'data:' +
                                acctMngSignImageType +
                                ';base64,' +
                                encode(acctMngSignImageData)
                              : ''
                          }
                          alt={'Signature of Account Manager'}
                        />
                      ) : (
                        ''
                      )}
                      <div>Signature of the Account Manager </div>
                    </div>
                    <div
                      style={{
                        padding: '5px',
                        height: '120px',
                        marginTop: '280px',
                        width: '214px',
                        alignItems: 'center',
                      }}
                      className="signate"
                    >
                      {imageData != null && imageData !== '' ? (
                        <img
                          height="90px"
                          width="100px"
                          src={
                            imageData != null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      ) : (
                        ''
                      )}
                      <div>Company Seal</div>
                    </div>
                  </div>
                </div>
                <p className="textcenter">
                  <strong>
                    Vodafone Idea Limited (formerly Idea Cellular Limited) An
                    Aditya Birla Group and Vodafone Partnership Merger Co CIN
                    No. L32100GJ1996PLC030976Registered Office: Suman Tower,
                    Plot No. 18 Sector 11, Gandhinagar-382011, Gujarat.
                  </strong>
                </p>
              </div>
            </div>
          </page>
          {excelJson !== null ? (
            <page size="A4">
              <div>
                <div className="pad_t10 section2">
                  <p className="customfom">
                    Annexure to MPLS CRF - Site details basis CRF number :-{' '}
                  </p>
                </div>
                <JsonToTable json={excelJson} />
                <div>
                  <p class="font10 " style={{ marginTop: '20px' }}>
                    <strong>Note:</strong> Address proofs will be required for
                    each of the sites listed above as part of standard
                    documentation guidelines; Master CAF referred here should be
                    signed by the same
                  </p>
                </div>

                <div class="fleft width50 pad_t10">
                  <p class="marb5">Account Manager Name</p>
                  <p class="margin0">
                    <input
                      class="form_col widthfull inputext"
                      type="text"
                      name="Account Manager Name"
                      readonly=""
                      value={
                        crfDataupdated.hasOwnProperty(8559)
                          ? crfDataupdated[8559].value
                          : ''
                      }
                      readOnly
                    />
                  </p>
                  <div className="signate" style={{ position: 'relative' }}>
                    <div className="signate_img input_sign">
                      {/* <img src={(acctMngSignImageData != null  && acctMngSignImageData != "") ? "data:"+imageType+";base64," + encode(acctMngSignImageData) : ""} 
            alt={"Account Manager Sign"}/> */}
                      {acctMngSignImageData !== null &&
                      acctMngSignImageData !== '' ? (
                        <img
                          src={
                            acctMngSignImageData != null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(acctMngSignImageData)
                              : ''
                          }
                          alt={'Account Manager Sign'}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div>Signature of the Account Manager </div>
                  </div>
                </div>
                <div
                  className="signate float_img"
                  style={{ position: 'initial' }}
                >
                  <div className="over_hid ">
                    <div className="over_hid ">
                      Authorised Signatory Name
                      <input
                        className="form_col inputext"
                        type="text"
                        name="Name_CD"
                        value={
                          crfDataupdated.hasOwnProperty(8376)
                            ? crfDataupdated[8376].value
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData != null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData != null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </page>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  } else {
    crfContentView = CrfData ? (
      <div>
        <Center p={2} my={1}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={chakraTheme.colors.secondary}
            color={chakraTheme.colors.brand[800]}
            size="md"
          />
        </Center>
      </div>
    ) : (
      <div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
        {' '}
        MPLS CRF Form not submitted yet.
      </div>
    );
  }
  return <div>{crfContentView}</div>;
};

export default CrfForm;
